
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  CLEAR_DETAIL_ENTERPRISE,
  FETCH_ENTERPRISE,
  FETCH_PROFILE,
  LOAD_ACTIVE_PROJECTS_LIST,
  LOAD_ENTERPRISE_LIST,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { saveEnterprise } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { EEncodingType, ESmartBarcodeMenu, EUserRole } from 'smartbarcode-web-core/src/utils/enums/index'
import { IEnterprise, IProject, IUser } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
interface IOption {
  value: string
  label: string
  disabled: boolean
}
@Options({
  components: { PageHeader, name: 'EtnerpriseForm' },
})
export default class EnterpriseForm extends mixins(ValidateForm) {
  isSaveDisabled = false
  enterpriseForm = {} as IEnterprise
  entitledPortalUIViews = {} as Record<string, boolean>

  isEditPassword = false
  rules = {
    email: [{ required: true, validator: this.requiredEmailValidate, trigger: 'blur' }],
    password: [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }],
    firstName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    lastName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    phoneNumber: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    roleType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    encodingType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  sideBarLabel: Record<string, string> = {
    projects: 'Projects',
    barcodePublish: 'barcodeImport.barcodeImport',
    barcodeSearch: 'barcode.search',
    dashboard: 'dashboard.dashboard',
    timetable: 'time_tables',
    carriers: 'Staff',
    clients: 'barcode_type.client',
    location: 'location.inventory',
  }

  visibleProjectCodes: string[] = []
  projectData: IOption[] = []

  loading = false

  onSaveEnterprise(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate((valid: string) => {
      if (valid) {
        this.saveEnterprise()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  get loggedUser(): IUser {
    return this.$store.state.profile?.user
  }

  get isOwnerRoleType() {
    return this.loggedUser.roleType === EUserRole.OWNER
  }

  async fetchProjects() {
    if (isEmpty(this.$store.state.project?.activeProjects)) {
      await this.$store.dispatch(LOAD_ACTIVE_PROJECTS_LIST)
    }

    this.projectData = this.$store.state.project?.activeProjects.map(
      (item: IProject) =>
        (({
          value: item.code ?? '',
          label: item.name ?? '',
          disabled: false,
        } as unknown) as IOption)
    )
  }

  get allPortalUIViews() {
    return [
      ESmartBarcodeMenu.PROJECT,
      ESmartBarcodeMenu.BARCODE_PUBLISH,
      ESmartBarcodeMenu.BARCODE_SEARCH,
      ESmartBarcodeMenu.DASHBOARD,
      ESmartBarcodeMenu.TIMETABLE,
      ESmartBarcodeMenu.CARRIER,
      ESmartBarcodeMenu.CLIENT,
      ESmartBarcodeMenu.LOCATION,
    ]
  }

  get getRoleTypes() {
    return Object.values(EUserRole)
  }

  get id() {
    return this.$route.params.id
  }

  async saveEnterprise() {
    try {
      this.loading = true

      await saveEnterprise(
        {
          ...this.enterpriseForm,
          visibleProjectCodes: this.visibleProjectCodes,
        },
        this.isEditPassword
      )

      openMessage(this.$t('save_successful'), 'success')

      await Promise.all([
        this.$store.dispatch(LOAD_ENTERPRISE_LIST),
        this.$store.dispatch(FETCH_PROFILE, { force: true }),
      ])
      this.onEnterpriseList()
    } catch (err) {
      errorHandler(err)
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  onEnterpriseList() {
    this.$router.push({ name: 'enterprise' })
  }

  get encodingTypes() {
    const encodingTypeArr = Object.values(EEncodingType)
    encodingTypeArr.length = 4
    return encodingTypeArr
  }

  onChangeEditPassword() {
    if (this.id) {
      this.rules.password = this.isEditPassword
        ? [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }]
        : []
    }
  }

  loadEnterpriseForm() {
    if (this.id) {
      this.loading = true
      this.$store
        .dispatch(FETCH_ENTERPRISE, this.id)
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'enterprise.admin' }))
        .finally(() => (this.loading = false))
    } else {
      this.$store.commit(CLEAR_DETAIL_ENTERPRISE)
    }
  }

  @Watch('$store.state.enterprise.enterprise')
  getInfo() {
    this.enterpriseForm = cloneDeep(this.$store.state.enterprise.enterprise)
    this.visibleProjectCodes = !isEmpty(this.enterpriseForm.visibleProjectCodes)
      ? this.enterpriseForm.visibleProjectCodes
      : []
    this.allPortalUIViews.forEach(
      (item) => (this.entitledPortalUIViews[item] = this.enterpriseForm?.entitledPortalUIViews?.includes(item) ?? false)
    )
  }

  fetchEnterprise() {
    this.onChangeEditPassword()
    this.messages.required = this.$t('this_field_is_required')
    this.messages.number = this.$t('please_enter_numeric')
    this.messages.email = this.$t('email_format_incorrect')
    this.messages.password = this.$t('please_enter_password')
    this.loadEnterpriseForm()
  }

  async created() {
    await this.fetchProjects()
    this.fetchEnterprise()
  }

  updateEntitledPortalUIViews() {
    this.enterpriseForm.entitledPortalUIViews = []
    for (const i in this.entitledPortalUIViews) {
      if (this.entitledPortalUIViews[i]) {
        ;(this.enterpriseForm.entitledPortalUIViews ?? []).push(i)
      }
    }
  }
}
