<template>
  <div v-loading.fullscreen="loading">
    <div class="flex pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader
          class="pr-6"
          :isHideRefresh="true"
          :title="$t(`enterprise.${id ? 'enterprise_user_edit' : 'enterprise_user_register'}`)"
          @load:refreshList="fetchEnterprise"
        />
        <el-button
          class="rounded-full py-3 px-10 bg-blue-500 text-white"
          :disabled="isSaveDisabled"
          @click="onSaveEnterprise('refEnterpriseForm')"
        >
          {{ $t('save') }}
        </el-button>
      </div>
      <div class="flex-grow flex justify-end header-container__right">
        <el-button type="primary" @click="onEnterpriseList()">
          {{ $t('enterprise.admin_menu') }}
        </el-button>
      </div>
    </div>
    <div class="py-8 text-lg">
      <div class="text-lg common-form-portal">
        <el-form
          class="form-container"
          status-icon
          :label-position="'left'"
          label-width="220px"
          :rules="rules"
          :model="enterpriseForm"
          ref="refEnterpriseForm"
        >
          <div class="sub-common-form">
            <el-form-item class="w-10/12" :label="$t('last_name')">
              <div class="flex">
                <div class="w-5/12">
                  <el-form-item prop="lastName">
                    <el-input
                      class="w-full"
                      v-model="enterpriseForm.lastName"
                      name="lastName"
                      autocomplete="on"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="w-2/12 el-form-item__label">{{ $t('first_name') }}</div>
                <div class="w-5/12">
                  <el-form-item prop="firstName">
                    <el-input
                      class="w-full"
                      v-model="enterpriseForm.firstName"
                      name="firstName"
                      autocomplete="on"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="w-10/12" prop="email" :label="$t('email_address')">
              <el-input v-model="enterpriseForm.email" name="email" autocomplete="on" size="medium"></el-input>
            </el-form-item>
            <el-form-item class="w-10/12" :label="$t('client.change_password')" v-if="id">
              <div class="flex">
                <div class="w-1/12">
                  <el-checkbox v-model="isEditPassword" @change="onChangeEditPassword"></el-checkbox>
                </div>
                <div class="w-11/12">
                  <el-form-item prop="password">
                    <el-input
                      :disabled="!isEditPassword"
                      type="password"
                      v-model="enterpriseForm.password"
                      name="password"
                      autocomplete="on"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="w-10/12" prop="password" :label="$t('enterprise.login_password')" v-else>
              <el-input
                v-model="enterpriseForm.password"
                type="password"
                name="password"
                autocomplete="on"
                size="medium"
              ></el-input>
            </el-form-item>
            <el-form-item class="w-10/12" prop="roleType" :label="$t('role_type')">
              <el-select default-first-option v-model="enterpriseForm.roleType" class="w-full">
                <el-option v-for="item in getRoleTypes" :key="item" :value="item" :label="$t(`roleType.${item}`)">
                  {{ $t(`roleType.${item}`) }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="w-10/12" prop="encodingType" :label="$t('enterprise.encoding_type')">
              <el-select default-first-option v-model="enterpriseForm.encodingType" class="w-full">
                <el-option v-for="item in encodingTypes" :key="item" :value="item" :label="$t(`enterprise.${item}`)">
                  {{ $t(`enterprise.${item}`) }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="entitledPortalUIViews" :label="$t('accessible_page')">
              <div class="flex flex-col">
                <el-checkbox
                  v-for="(item, key) in entitledPortalUIViews"
                  :key="key"
                  v-model="entitledPortalUIViews[key]"
                  @change="updateEntitledPortalUIViews()"
                  >{{ $t(sideBarLabel?.[key] ?? '') }}</el-checkbox
                >
              </div>
            </el-form-item>
            <el-form-item
              v-if="isOwnerRoleType"
              prop="entitledPortalUIViews"
              :label="$t('projects.project_visibility_setting')"
            >
              <el-transfer
                v-model="visibleProjectCodes"
                :filter-placeholder="$t('projects.projectName')"
                :titles="[$t('projects.invisible_project'), $t('projects.visible_project')]"
                :props="{
                  key: 'value',
                  label: 'label',
                }"
                :data="projectData"
                filterable
              />
            </el-form-item>
            <el-form-item prop="isActive" :label="$t('status')">
              <el-checkbox v-model="enterpriseForm.isActive" :disabled="!enterpriseForm.isEdit">{{
                $t('active')
              }}</el-checkbox>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  CLEAR_DETAIL_ENTERPRISE,
  FETCH_ENTERPRISE,
  FETCH_PROFILE,
  LOAD_ACTIVE_PROJECTS_LIST,
  LOAD_ENTERPRISE_LIST,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { saveEnterprise } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { EEncodingType, ESmartBarcodeMenu, EUserRole } from 'smartbarcode-web-core/src/utils/enums/index'
import { IEnterprise, IProject, IUser } from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
interface IOption {
  value: string
  label: string
  disabled: boolean
}
@Options({
  components: { PageHeader, name: 'EtnerpriseForm' },
})
export default class EnterpriseForm extends mixins(ValidateForm) {
  isSaveDisabled = false
  enterpriseForm = {} as IEnterprise
  entitledPortalUIViews = {} as Record<string, boolean>

  isEditPassword = false
  rules = {
    email: [{ required: true, validator: this.requiredEmailValidate, trigger: 'blur' }],
    password: [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }],
    firstName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    lastName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    phoneNumber: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    roleType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    encodingType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  sideBarLabel: Record<string, string> = {
    projects: 'Projects',
    barcodePublish: 'barcodeImport.barcodeImport',
    barcodeSearch: 'barcode.search',
    dashboard: 'dashboard.dashboard',
    timetable: 'time_tables',
    carriers: 'Staff',
    clients: 'barcode_type.client',
    location: 'location.inventory',
  }

  visibleProjectCodes: string[] = []
  projectData: IOption[] = []

  loading = false

  onSaveEnterprise(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate((valid: string) => {
      if (valid) {
        this.saveEnterprise()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  get loggedUser(): IUser {
    return this.$store.state.profile?.user
  }

  get isOwnerRoleType() {
    return this.loggedUser.roleType === EUserRole.OWNER
  }

  async fetchProjects() {
    if (isEmpty(this.$store.state.project?.activeProjects)) {
      await this.$store.dispatch(LOAD_ACTIVE_PROJECTS_LIST)
    }

    this.projectData = this.$store.state.project?.activeProjects.map(
      (item: IProject) =>
        (({
          value: item.code ?? '',
          label: item.name ?? '',
          disabled: false,
        } as unknown) as IOption)
    )
  }

  get allPortalUIViews() {
    return [
      ESmartBarcodeMenu.PROJECT,
      ESmartBarcodeMenu.BARCODE_PUBLISH,
      ESmartBarcodeMenu.BARCODE_SEARCH,
      ESmartBarcodeMenu.DASHBOARD,
      ESmartBarcodeMenu.TIMETABLE,
      ESmartBarcodeMenu.CARRIER,
      ESmartBarcodeMenu.CLIENT,
      ESmartBarcodeMenu.LOCATION,
    ]
  }

  get getRoleTypes() {
    return Object.values(EUserRole)
  }

  get id() {
    return this.$route.params.id
  }

  async saveEnterprise() {
    try {
      this.loading = true

      await saveEnterprise(
        {
          ...this.enterpriseForm,
          visibleProjectCodes: this.visibleProjectCodes,
        },
        this.isEditPassword
      )

      openMessage(this.$t('save_successful'), 'success')

      await Promise.all([
        this.$store.dispatch(LOAD_ENTERPRISE_LIST),
        this.$store.dispatch(FETCH_PROFILE, { force: true }),
      ])
      this.onEnterpriseList()
    } catch (err) {
      errorHandler(err)
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  onEnterpriseList() {
    this.$router.push({ name: 'enterprise' })
  }

  get encodingTypes() {
    const encodingTypeArr = Object.values(EEncodingType)
    encodingTypeArr.length = 4
    return encodingTypeArr
  }

  onChangeEditPassword() {
    if (this.id) {
      this.rules.password = this.isEditPassword
        ? [{ required: true, validator: this.requiredPasswordValidate, trigger: 'blur' }]
        : []
    }
  }

  loadEnterpriseForm() {
    if (this.id) {
      this.loading = true
      this.$store
        .dispatch(FETCH_ENTERPRISE, this.id)
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'enterprise.admin' }))
        .finally(() => (this.loading = false))
    } else {
      this.$store.commit(CLEAR_DETAIL_ENTERPRISE)
    }
  }

  @Watch('$store.state.enterprise.enterprise')
  getInfo() {
    this.enterpriseForm = cloneDeep(this.$store.state.enterprise.enterprise)
    this.visibleProjectCodes = !isEmpty(this.enterpriseForm.visibleProjectCodes)
      ? this.enterpriseForm.visibleProjectCodes
      : []
    this.allPortalUIViews.forEach(
      (item) => (this.entitledPortalUIViews[item] = this.enterpriseForm?.entitledPortalUIViews?.includes(item) ?? false)
    )
  }

  fetchEnterprise() {
    this.onChangeEditPassword()
    this.messages.required = this.$t('this_field_is_required')
    this.messages.number = this.$t('please_enter_numeric')
    this.messages.email = this.$t('email_format_incorrect')
    this.messages.password = this.$t('please_enter_password')
    this.loadEnterpriseForm()
  }

  async created() {
    await this.fetchProjects()
    this.fetchEnterprise()
  }

  updateEntitledPortalUIViews() {
    this.enterpriseForm.entitledPortalUIViews = []
    for (const i in this.entitledPortalUIViews) {
      if (this.entitledPortalUIViews[i]) {
        ;(this.enterpriseForm.entitledPortalUIViews ?? []).push(i)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

:deep() {
  .el-transfer-panel {
    width: 350px;
  }
}
</style>
